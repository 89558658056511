// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js/features/dom-collections/for-each'

import 'regenerator-runtime/runtime'
import 'core-js/stable/array/find'
import 'core-js/stable/dom-collections/for-each'
import 'core-js/stable/string/includes'

window.Rails = require("@rails/ujs")
Rails.start()

require("@rails/activestorage").start()
require("@fancyapps/fancybox")
require('lib/event_countdown')

import '../../lib/common'
import 'components/search'
import 'components/dropdown-hover'
import 'hammerjs'
import 'components/notification'
import 'components/event'
import 'components/dom_polyfills'
import 'components/notice_email_confirmation'
import 'components/scroll-header'
import 'components/traffic_factory'
import 'components/goal_tracking'
import { Application } from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers";
import 'components/picture_lazy_loading'

import 'bootstrap/js/dist/tab';

const application = Application.start()
const context = require.context("../../lib/controllers", true, /\.js$/)
const sharedContext = require.context("../../shared/controllers", true, /\.js$/)
application.load(definitionsFromContext(context));
application.load(definitionsFromContext(sharedContext));

import { Turbo } from "@hotwired/turbo-rails"
