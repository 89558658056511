import Cookies from 'js-cookie';

let TrafficFactory = {
  click() {
    $.ajax({
      url: '/traffic_factory/first_click',
      type: 'POST',
    });
  },

  payout() {
    $.ajax({
      url: '/traffic_factory/payout',
      type: 'POST',
    });
  },
}

function shouldExecuteCallbacks() {
  let campaign = Cookies.get('utm_campaign');

  return Cookies.get('country') &&
           Cookies.get('utm_content') &&
           Cookies.get('tfy_cpa_token') &&
           Cookies.get('logged') !== 'true' &&
           campaign &&
           Cookies.get('tf_first_click_' + campaign) === undefined &&
           Cookies.get('tf_tracking_' + campaign) === undefined;
}

$(() => {
  $('body').on('click', () => {
    if (shouldExecuteCallbacks()) {
      TrafficFactory.click();
      TrafficFactory.payout();
    }

    $('body').off('click');
  });
});
