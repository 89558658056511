import * as debounce from "debounce";

$(function () {
  let prevScrollpos = window.pageYOffset;

  let $navbar = $('#navbar');

  window.onscroll = debounce(function () {
    let currentScrollPos = window.pageYOffset;
    if (currentScrollPos < 65) {
      $navbar.removeClass('navbar--scrolled');
      $('body').removeClass('navbar-fixed');
      $navbar.css({top: '0', position: 'relative'});
    } else if (prevScrollpos > currentScrollPos) {
      $navbar.addClass('navbar--scrolled');
      $('body').addClass('navbar-fixed');
      $navbar.css({top: '0', position: 'fixed'});
    } else {
      $('body').addClass('navbar-fixed');
      $navbar.css({top: '-64px', position: 'fixed'});
    }
    prevScrollpos = currentScrollPos;
  }, 50)

});
