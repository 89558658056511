$(function () {
  if (!(/google/i).test(navigator.userAgent)) {
    let $dropdown = $('.dropdown--notification');
    let $bell = $('.header .dropdown--notification .nav-icon.bell');
    let notificationIds = localStorage.getItem('notificationIds');
    if (notificationIds) {
      notificationIds = JSON.parse(notificationIds)
    } else {
      notificationIds = []
    }

    let $items = $('.header .notification-item');
    $items.each(function (i, item) {
      if (notificationIds.indexOf(item.id) > -1) {
        $(item).removeClass('unread')
      }
    });

    //after close notification dropdown add all unread id to array of read notification
    $dropdown.on('dropdown.close', function () {
      $items.each(function (i, item) {
        $(item).removeClass('unread');
        if (notificationIds.indexOf(item.id) === -1) {
          notificationIds.push(item.id)
        }
      });
      $bell.removeClass('new');
      localStorage.setItem('notificationIds', JSON.stringify(notificationIds))
    })

    //add notifications id to array of read notifications if user open /notification page from mobile
    $('.notifications-page .notification-item').each(function (i, item) {
      if (notificationIds.indexOf(item.id) === -1) {
        notificationIds.push(item.id)
      } else {
        $(item).removeClass('unread');
      }
    });

    localStorage.setItem('notificationIds', JSON.stringify(notificationIds))

    //show unread notification count on mobile menu
    if ($dropdown.find('.unread').length > 0) {
      $bell.addClass('new')
    }
  }
});
