import Cookies from 'js-cookie';

let $noticeContainer = $('.notice.notice--change-locale');

$('.notice--change-locale .notice__close').on('click', function () {
    Cookies.set(
        'noticeChangeLocale', 'true', {expires: 30}
    );
    $noticeContainer.fadeOut();
});
