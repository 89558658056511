import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["priceField", "paymentProcessor"]

    connect() {
        let that = this
        this.updatePriceHints()

        const radios = this.element.querySelectorAll('input[name=payment_method]')
        for (const radio of radios) {
            radio.onclick = (e) => {
                that.updatePriceHints()
            }
        }
    }

    updatePriceHints() {
        let selectedPaymentProcessor = Array.from(this.paymentProcessorTargets).find((radio) => radio.checked);
        if (selectedPaymentProcessor === undefined) {
            this.hideHints()
        } else {
            this.showHints(selectedPaymentProcessor.dataset.currency, parseFloat(selectedPaymentProcessor.dataset.ratio))
        }
    }

    hideHints() {
        this.priceFieldTargets.forEach((priceField) => {priceField.innerHTML = ''});
    }

    showHints(symbol, ratio) {
        this.priceFieldTargets.forEach(
            (priceField) => {
                let hintAmount = (parseFloat(priceField.dataset.amount) * ratio).toFixed(2);
                priceField.innerHTML = `(~${symbol}${hintAmount})`
            }
        );
        // Performs reflow so that the text is shown properly (hintAmount steps over symbol on FF without it)
        // Doing it here, so that it's only done once
        void(this.priceFieldTarget.offsetHeight)
    }
}
