class GtmLogger {
  static log(data) {
    $.ajax({
      url: '/gtm_logger',
      method: 'POST',
      data: data
    });
  }
}

export default GtmLogger;
