if (!Element.prototype.closest) {
  Element.prototype.closest = function(css) {
    var node = this;

    while (node) {
      if (node.matches(css)) return node;
      else node = node.parentElement;
    }
    return null;
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.matchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector
}

Document.prototype.closeFullscreen = function() {
  if (!this.isFullScreen()) return

  if (this.exitFullscreen) {
    this.exitFullscreen();
  } else if (this.mozCancelFullScreen) {
    this.mozCancelFullScreen();
  } else if (this.webkitExitFullscreen) {
    this.webkitExitFullscreen();
  } else if (this.msExitFullscreen) {
    this.msExitFullscreen();
  }
}

Document.prototype.isFullScreen = function() {
  return this.fullScreen || this.mozFullScreen ||
  this.webkitIsFullScreen || (this.msFullscreenElement != null)
}