import $ from 'jquery'
import isTouchDevice from "../commons/isTouchDevice";

function dropdownShow (e) {
  e.preventDefault();
  let $dropdown = $(e.target).closest('.dropdown');
  $dropdown.addClass('show');
  $dropdown.find('.dropdown-menu').show();
}

function dropdownHide (e) {
  e.preventDefault();
  let $dropdown = $(e.target).closest('.dropdown');
  $dropdown.removeClass('show');
  $dropdown.trigger('dropdown.close');
  $dropdown.find('.dropdown-menu').hide();
}

function dropdownClickItem (e) {
  let $dropdown = $(e.target).closest('.dropdown');
  $dropdown.removeClass('show');
  $dropdown.find('.dropdown-menu').hide();
}

$(function () {
  if (isTouchDevice()) return
  $(document).on('mouseover', '.dropdown-hover', dropdownShow)
  $(document).on('mouseleave', '.dropdown-hover', dropdownHide)

  $(document).on('click', 'dropdown-hover .dropdown-item', dropdownClickItem)
});
