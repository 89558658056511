import GtmLogger from 'gtm_logger';
import Cookies from 'js-cookie';

class TrackPayment {
  constructor(args, callback) {
    this.args = args;
    this.saveToStorage();
    TrackPayment.triggerEvent(callback);
  }

  saveToStorage() {
    for (const property in this.args) {
      window.localStorage.setItem(property, this.args[property]);
    }
  }

  static triggerEvent(callback) {
    let maxRetries = 2;
    let retryCount = 0;

    const interval = setInterval(() => {
      try {
        window.dataLayer.push({ 'event': 'payment_info', 'eventCallback': callback });
        clearInterval(interval);
      } catch(err) {
        GtmLogger.log({
          message: 'GTM not initialized: ' + err,
          url: window.location.href,
          user_id: Cookies.get('user_id'),
          user_agent: navigator.userAgent
        });
      }

      if (++retryCount >= maxRetries) {
        GtmLogger.log({
          message: 'GTM not initialized after 2 retries',
          url: window.location.href,
          user_id: Cookies.get('user_id'),
          user_agent: navigator.userAgent
        });
        clearInterval(interval);
        callback();
      }
    }, 1000);
  }
}

window.TrackPayment = TrackPayment;
