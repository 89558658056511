$(function () {

  $('.event-clock, .clock').each(function () {
    let $el = $(this);

    let endDate = new Date($el.attr('data-start-datetime').replace(/\"/g, ''));
    if (endDate <= (new Date())) {
      endDate = new Date($el.attr('data-end-datetime').replace(/\"/g, ''));
    }

    let $days = $el.find('.days');
    let $hours = $el.find('.hours');
    let $minutes = $el.find('.minutes');
    let $seconds = $el.find('.seconds');

    showDiff();

    function showDiff() {
      let nowDate = new Date();
      //Customise date2 for your required future time

      let diff = (endDate - nowDate) / 1000;
      diff = Math.floor(diff);

      if (diff < 0) diff = 0;

      let days = Math.floor(diff / (24 * 60 * 60));
      let leftSeconds = diff - days * 24 * 60 * 60;

      let hours = Math.floor(leftSeconds / (60 * 60));
      leftSeconds = leftSeconds - hours * 60 * 60;

      let minutes = Math.floor(leftSeconds / (60));
      leftSeconds = leftSeconds - minutes * 60;

      $days.text(days);
      $hours.text(hours < 10 ? '0' + hours : hours);
      $minutes.text(minutes < 10 ? '0' + minutes : minutes);
      $seconds.text(leftSeconds < 10 ? '0' + leftSeconds : leftSeconds);

      setTimeout(showDiff, 1000);
    }
  });


});
