import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "relatedToggle" ]

    updateAll(event) {
        let toggleIdentifier = event.params.elementToChange;
        let reverseAction = event.params.reverseAction;
        let reverseOnUncheck = event.params.reverseOnUncheck;

        if (event.currentTarget.checked) {
            this.changeToggles(!!reverseAction, toggleIdentifier);
        } else if (!!reverseOnUncheck) {
            this.changeToggles(!reverseAction, toggleIdentifier);
        }
    }

    changeToggles(checkedStatus, toggleIdentifier) {
        this.relatedToggleTargets.filter(function(relatedToggle) {
            return relatedToggle.dataset.jsToggleIdentifier === toggleIdentifier
        }).forEach(function(target){
            if (target.checked !== checkedStatus) {
                target.checked = checkedStatus;
                target.dispatchEvent(new Event('input'));
            }
        })
    }
}
