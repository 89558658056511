import { v4 as uuidv4 } from 'uuid';

class GoalTracking {
  constructor(uid) {
    this.uid = uid;
  }

  perform() {
    GoalTracking.request(this.endpoint);
  }

  get endpoint() {
    return '/tracking/goal/eroges-app-landing-page/download/' + this.uid;
  }

  static request(url) {
    $.get(url);
  }
}

$(() => {
  // Tracking is mostly used by our mobile apps
  // and with each request they send uid param.
  // In this case we need to generate one.
  let uid = uuidv4();

  $('.eroges-app-download').click(() => {
    new GoalTracking(uid).perform();
    $('.eroges-app-download').off('click');
  });
});
