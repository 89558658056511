$(function () {

  let $nav = $('.nav-icon.events, .mobile-only.nav-icon.events');

  let lastId = $nav.attr('data-last-event-id')
  if (!lastId) lastId = 0;

  if (!localStorage) {
    if (lastId > 0) {
      $nav.addClass('new');
    }
    return
  }

  let userLastId = localStorage.getItem('last-event-id');
  if (!userLastId) userLastId = 0;

  if (lastId > userLastId) $nav.addClass('new');

  if ($('body.events').length > 0) {
    localStorage.setItem('last-event-id', lastId)
  }
});
