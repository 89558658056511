import Cookies from 'js-cookie';

$(() => {
  let payment = Cookies.get('payment_alert');

  try {
    payment = JSON.parse(payment);
  } catch(e) {
    payment = undefined;
  }

  if (payment !== undefined && typeof TrackPayment === 'function') {
    new TrackPayment({
      order_id: payment.order_id,
      payment_status: payment.payment_status,
      payment_amount: payment.payment_amount,
      payment_method: payment.payment_method
    }, () => {});

    Cookies.remove('payment_alert');
  }
});
