$(function () {
  let $forms = $('form[submit-disable-validation]');
  $forms.each(function (i, form) {
    let $form = $(form);
    if (!form.checkValidity) return;

    if (form.checkValidity() === false) {
      $form.find('input[type=submit]').attr('disabled','disabled');
    }

    $form.find('input[required],textarea').on('keyup change', function () {
      if (form.checkValidity() === false) {
        $form.find('[type=submit]').attr('disabled','disabled');
        $form.find('input[type=email]').each(function (i, field) {
          if (!field.validity.valid)  {
            $(field).closest('.form-group').addClass('error')
          } else {
            $(field).closest('.form-group').removeClass('error')
          }
        })
      } else {
        $form.find('[type=submit]').removeAttr('disabled');
      }
    })
  })

  $('.is-invalid').each(function (i, field){
    $(field).closest('.form-group').addClass('error')
  })
});

