const $search = $('.search');
$('.search__container').click(function() {
    $search.addClass('active');
})

$(document).click(function (e) {
    if ($search.hasClass('active') && !$search.has(e.target).length && $search[0] !== e.target ) closeSearch()
})

$('#searchClose').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    closeSearch();
})

$('#searchResultClear').click(function(e) {
    e.preventDefault();
   $('.search-result__box').val('').focus();
})

function closeSearch() {
    $('.search__box').val('');
    $('.js-search-results').removeClass('showing');
    $search.removeClass('active');
}
