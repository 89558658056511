$(function () {
  function dropdown (e, data) {
    e.preventDefault();
    let $dropdown = $(this).closest('.dropdown');

    if ($dropdown.hasClass('show')) {
      $dropdown.trigger('dropdown.close')
    } else {
      //close other dropdown
      $('.dropdown.show').find('.dropdown-link').trigger('click',{'closeForce':true})
    }

    $dropdown.toggleClass('show');

    if (data && data.closeForce) {
      $dropdown.find('.dropdown-menu').hide();
    } else {
      //show immediately without fadeIn
      if ($dropdown.hasClass('show')) {
        $dropdown.find('.dropdown-menu').show();
      } else {
        $dropdown.find('.dropdown-menu').fadeToggle('250');
      }
    }
  }
  $('.dropdown:not(.dropdown-lazy) .dropdown-link').on('click',dropdown);

  $(document).on('click','.dropdown-lazy .dropdown-link', dropdown)

  $(document).click(function (event) {
    let $trigger = $('.dropdown');
    if ($trigger !== event.target && !$trigger.has(event.target).length) {
      if ($trigger.hasClass('show')) {
        $trigger.removeClass('show').find('.dropdown-menu').fadeOut('250');
        $trigger.trigger('dropdown.close')
      }
    }
  });

  $('.overlay-mask').click(function (event) {
    event.preventDefault();
    let $trigger = $('.dropdown');
    if ($trigger.hasClass('show')) {
      $trigger.removeClass('show').find('.dropdown-menu').fadeOut('250');
      $trigger.trigger('dropdown.close')
    }
  })

  // Item comments sorting/filtering dropdowns

  $('.dropdown--sorting .dropdown-item').on('click', function(e) {
    $(e.currentTarget).closest('ul').find('.dropdown-item').removeClass('active')
    $(e.currentTarget).addClass('active')
    // update text of chosen element
    $(e.currentTarget).closest('.dropdown--sorting').find('.dropdown-link p').text($(e.currentTarget).find('p').text())
    // close dropdown
    $(e.currentTarget).closest('.dropdown').removeClass('show').find('.dropdown-menu').fadeOut('250')
  })
});
