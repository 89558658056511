import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["searchResults", "searchItem", "mainForm"]

    requestSearch() {
        const resultsArea = document.querySelector('.js-search-results')
        if (this.searchItemTarget.value.length > 0) {
            fetch(
                this.mainFormTarget.action + '&search_query=' + this.searchItemTarget.value,
                {headers: {'X-Requested-With': 'XMLHttpRequest'}}
            )
                .then(response => {
                    return response.text();
                })
                .then(html => {
                    if ($(html).children().length > 0) {
                        resultsArea.innerHTML = html;
                        resultsArea.classList.add("showing")
                    }
                });
        } else {
            resultsArea.classList.remove("showing")
        }

    }
}
