import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "hideableSection", "hideableTrigger" ]
    static values = {
        disableInputs: Boolean
    }

    connect() {
        this.toggleVisibility();
    }

    toggleVisibility() {
        const targetElements = this.hideableSectionTargets;
        const triggerIsChecked = $(this.hideableTriggerTarget).is(":checked");
        const triggerIsReversed = !!$(this.hideableTriggerTarget).data("hideable-reversed");
        let that = this;

        targetElements.forEach(function(targetElement) {
            if (triggerIsChecked) {
                if (triggerIsReversed) {
                    that.enableInputsWithin(targetElement);
                    $(targetElement).show();
                } else {
                    $(targetElement).hide();
                    that.disableInputsWithin(targetElement);
                }
            } else {
                if (triggerIsReversed) {
                    $(targetElement).hide();
                    that.disableInputsWithin(targetElement);
                } else {
                    that.enableInputsWithin(targetElement);
                    $(targetElement).show();
                }
            }
        })

        $(".subscription-item--last-visible").removeClass("subscription-item--last-visible")
        $(".subscriptions__wrap.subscriptions__wrap--first > div:visible:last").addClass("subscription-item--last-visible")
    }

    disableInputsWithin(targetElement) {
        if (this.hasDisableInputsValue) {
            $(targetElement).find(":input").prop('disabled', 'disabled');
        }
    }

    enableInputsWithin(targetElement) {
        if (this.hasDisableInputsValue) {
            $(targetElement).find(":input").prop('disabled', false);
        }
    }
}
