import cookies from 'js-cookie';

let $containers = $('[data-postpone-name]');

$containers.find('[data-postpone-time]').on('click', function (e) {
    e.stopPropagation()
    e.preventDefault()
    const $container = $(this).closest('[data-postpone-name]')
    $container.hide();
    const name = $container.attr('data-postpone-name')
    const time = parseFloat($(this).attr('data-postpone-time'))
    cookies.set(name, 'hide', {expires: time});
});
